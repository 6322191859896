import React from 'react';
import { useLocalization } from '@/services/localizationService';

export default function Footer({ className = '' }: { className?: string }) {
  const { t, data } = useLocalization();

  return (
    <footer
      className={`container py-8 max-w-md text-overlay-white-40 ${className}`}
    >
      <button
        type="button"
        className="R11 rounded-3xl px-2 py-1.5 space-s-2 border-1.5 items-end justify-end transition-colors mb-2 border-overlay-white-15 hover:bg-overlay-white-5"
        onClick={() => {
          (window as any).OneTrust?.ToggleInfoDisplay?.();
        }}
      >
        {t(data.footer.cookiePrefs)}
      </button>
      <p className="R10 mb-2">
        Copyright © AI Creativity S.r.l. | Via Nino Bonnet 10, 20154 Milan,
        Italy | VAT, tax code, and number of registration with the Milan Monza
        Brianza Lodi Company Register 13250480962 | REA number MI 2711925 |
        Contributed capital €10.000,00 | Sole shareholder company subject to the
        management and coordination of Bending Spoons S.p.A.
      </p>
    </footer>
  );
}
