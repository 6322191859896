import { trackEvent } from '@/core/monitoring/trackEvents';

function trackGiftCodeEvent(
  event: 'submitted' | 'accepted' | 'failed',
  redeemCode: string | undefined,
  error?: unknown
) {
  const info =
    event === 'failed' && error
      ? { redeemCode, error: `${error}` }
      : { redeemCode };
  trackEvent(['giftcode', event], info);
}

export const giftCodeTracker = {
  trackSubmitted(redeemCode: string | undefined) {
    trackGiftCodeEvent('submitted', redeemCode);
  },
  trackAccepted(redeemCode: string | undefined) {
    trackGiftCodeEvent('accepted', redeemCode);
  },
  trackFailed(redeemCode: string | undefined, error?: unknown) {
    trackGiftCodeEvent('failed', redeemCode, error);
  },
};
