import useMediaQuery from '@/core/listeners/useMediaQuery';
import { useCallback, useMemo, useState } from 'react';

const makeResponsiveSources = (index: number) => ({
  mobile: {
    before: `/images/home/${index + 1}/mobile/before.webp`,
    after: `/images/home/${index + 1}/mobile/after.webp`,
  },
  desktop: {
    before: `/images/home/${index + 1}/before.webp`,
    after: `/images/home/${index + 1}/after.webp`,
  },
});

enum WindowSize {
  SMALL,
  MEDIUM,
  LARGE,
}
const getComparerConfig = (size: WindowSize, homepageImage: number) => {
  const responsiveSources = makeResponsiveSources(homepageImage);
  if (size === WindowSize.SMALL) {
    return {
      beforeSrc: responsiveSources.mobile.before,
      afterSrc: responsiveSources.mobile.after,
      initialSliderProgress: 0.5,
      maxSliderProgress: 1,
    };
  }
  if (size === WindowSize.MEDIUM) {
    return {
      beforeSrc: responsiveSources.desktop.before,
      afterSrc: responsiveSources.desktop.after,
      initialSliderProgress: 0.325,
      maxSliderProgress: 0.4,
    };
  }
  return {
    beforeSrc: responsiveSources.desktop.before,
    afterSrc: responsiveSources.desktop.after,
    initialSliderProgress: 0.325,
    maxSliderProgress: 0.5,
  };
};

export const useResponsiveComparer = () => {
  const homepageImage = 1;
  const [comparerConfig, setComparerConfig] = useState({
    beforeSrc: '',
    afterSrc: '',
    initialSliderProgress: 0,
    maxSliderProgress: 0,
  });

  const applyComparerConfig = useCallback(
    (size: WindowSize) => (doesMatch: boolean) => {
      if (doesMatch) {
        setComparerConfig(getComparerConfig(size, homepageImage));
      }
    },
    [homepageImage]
  );

  const comparerConfigCb = useMemo(
    () => ({
      small: applyComparerConfig(WindowSize.SMALL),
      medium: applyComparerConfig(WindowSize.MEDIUM),
      large: applyComparerConfig(WindowSize.LARGE),
    }),
    [applyComparerConfig]
  );

  useMediaQuery('(max-width: 1024px)', comparerConfigCb.small);
  useMediaQuery(
    '(min-width: 1024px) and (max-width: 1300px)',
    comparerConfigCb.medium
  );
  useMediaQuery('(min-width: 1300px)', comparerConfigCb.large);

  return comparerConfig;
};
