import { useCallback } from 'react';
import { useLocalization } from '@/services/localizationService';
import LinkWithQuery from '@/ui/content/LinkWithQuery';

export const useLegalCopy = () => {
  const { t, data } = useLocalization();

  const onTermsOfServiceClick = useCallback((e) => e.stopPropagation(), []);
  const onPrivacyPolicyClick = useCallback((e) => e.stopPropagation(), []);

  return {
    acceptTermsText: t(data.common.byContinuingAcceptTosAndPrivacy, {
      tos: (text) => (
        <a
          className="underline"
          href="https://bendingspoons.com/tos.html?app=1470373330"
          target="_blank"
          rel="noreferrer nofollow"
          onClick={onTermsOfServiceClick}
        >
          {text}
        </a>
      ),
      privacy: (text) => (
        <LinkWithQuery path="/privacy-and-cookie-policy">
          <a
            className="underline"
            target="_blank"
            onClick={onPrivacyPolicyClick}
          >
            {text}
          </a>
        </LinkWithQuery>
      ),
    }),
  };
};
