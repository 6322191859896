import { useSetRecoilState } from 'recoil';
import { ModalWrapper } from '@/core/modal';
import { ErrorAlert } from '@/ui/content';
import { closeModalSelector } from '@/stores/modalStore';
import { useUser } from '@/services/userService';

export default function RedeemModal({
  id,
  hasError,
  errorMessage,
  onClose,
}: {
  id: string;
  hasError: boolean;
  errorMessage: string;
  onClose?: any;
}) {
  const closeModal = useSetRecoilState(closeModalSelector);
  const { data: user } = useUser();

  return (
    <ModalWrapper
      onClose={onClose}
      className="p-8 px-6 md:p-12 section-foreground rounded-3xl"
    >
      {!hasError && !user?.giftCode.isActive && (
        <div className="space-y-4 text-center">
          <h3 className="SB20 text-center">Gift Code Added ✅</h3>
          <p>The gift code has been added to your account!</p>
          <button
            type="button"
            className="btn btn--large btn--primary mt-4 mx-auto"
          >
            Ok, Got It
          </button>
        </div>
      )}
      {hasError && (
        <ErrorAlert title={errorMessage}>
          <div className="space-y-4">
            <button
              type="button"
              className="btn btn--large btn--primary mt-4 mx-auto"
              onClick={() => closeModal(id)}
            >
              Ok, Got It
            </button>
          </div>
        </ErrorAlert>
      )}
    </ModalWrapper>
  );
}
