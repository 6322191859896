import { Comparer } from '@/features/comparer';
import sketchIcon from '@/public/images/icons/home-sketch.svg';
import photoIcon from '@/public/images/icons/photo.svg';
import uploadWhiteIcon from '@/public/images/icons/upload-white.svg';
import videoIcon from '@/public/images/icons/video.svg';
import { motion } from 'framer-motion';

import { LegalCopy } from '@/features/legal/legalCopy/LegalCopy';
import { UploaderLabel } from '@/features/uploadMedia/UploaderLabel';
import { UploaderRoot } from '@/features/uploadMedia/UploaderRoot';
import { TryVideoEnhanceBadge } from '@/features/videoEnhance/TryVideoEnhanceBadge/TryVideoEnhanceBadge';
import { MenuActions } from '@/layout/menuActions';
import dropdownIcon from '@/public/images/icons/down_small.svg';
import { useLocalization } from '@/services/localizationService';
import { useUser } from '@/services/userService';
import Footer from '../Footer';
import Header from '../Header';
import { PageLayout } from '../PageLayout';
import { Navigation } from '../navigation';
import { AnimatedDiv } from './animatedDiv/AnimatedDiv';
import { useHomeLayout } from './useHomeLayout';

export function HomeLayout() {
  const {
    filePickerId,
    comparerConfig,
    onComparerSlide,
    onUpload,
    titleText,
    subtitleText,
    chooseFileText1,
    chooseFileText2,
    photosText,
    videosText,
    scrollToFooterText,
    animateHeader,
    hideFooter,
    onScroll,
    settings,
    bipaText,
  } = useHomeLayout();

  const { t, data } = useLocalization();
  const { data: user } = useUser();

  return (
    <PageLayout title="Photo Enhancer">
      <UploaderRoot id={filePickerId} onUpload={onUpload} onScroll={onScroll}>
        <Header
          className={`fixed z-20 lg:z-40 top-0 start-0 transition-colors duration-200 ${
            animateHeader
              ? 'lg:bg-overlay-black-20 lg:backdrop-blur-[28px]'
              : ''
          }`}
        >
          {' '}
          <MenuActions className="hidden md:flex" />
          <Navigation.Button className="md:hidden" />
        </Header>
        <Navigation className="fixed z-30 inset-0 md:hidden">
          <MenuActions />
        </Navigation>
        <div className="relative h-96 sm:h-half-screen lg:h-full lg:fixed inset-0 z-0">
          {comparerConfig.beforeSrc && comparerConfig.afterSrc && (
            <Comparer
              className="w-full h-full"
              backgroundColor={0x000}
              initialFit="cover"
              disablePan
              disablePinch
              {...comparerConfig}
              onSlide={onComparerSlide}
              sliderBehavior="hover"
            />
          )}
          <div className="lg:hidden absolute h-20 bottom-0 w-full bg-gradient-to-t from-black-background" />
        </div>
        <main
          className={`relative z-10 container mt-6 md:mt-12 ${
            settings.values.bipaDisclaimerEnabled
              ? 'lg:short:mt-20'
              : 'lg:short:mt-12'
          } lg:mt-2 lg:min-h-[95%] flex pointer-events-none`}
        >
          <div className="m-auto lg:mr-0 pointer-events-auto text-center lg:text-left">
            <div className="max-w-lg lg:max-w-md space-y-4">
              <AnimatedDiv>
                {!user?.monetization?.hasIncompleteSubscription && (
                  <TryVideoEnhanceBadge />
                )}
                <h1 className="lg:text-start relative text-[48px] font-medium leading-[56px] tracking-[-2px]">
                  {titleText}{' '}
                  <span className="relative">
                    {t(data.home.click)}
                    <img
                      className="absolute right-0"
                      draggable={false}
                      {...sketchIcon}
                      alt="sketch"
                    />
                  </span>
                </h1>
              </AnimatedDiv>
              <AnimatedDiv>
                <p className="lg:text-start R16+LH text-opacity-60">
                  {subtitleText}
                </p>
              </AnimatedDiv>
            </div>

            <AnimatedDiv className="mt-12 max-w-md mx-auto">
              <UploaderLabel
                forId={filePickerId}
                className="block lg:w-[420px] w-[100%] px-8 pt-10 pb-8 text-center rounded-[32px] border-3 border-dashed border-overlay-white-30 bg-gradient-to-br from-[rgba(0,0,0,0.09)] to-[rgba(0,0,0,0.3)] hover:bg-overlay-white-5 transition-colors"
              >
                <div className="btn btn--large btn--primary w-max mx-auto py-1 px-6 ps-8 flex justify-center items-center rounded-full">
                  {chooseFileText1}
                  <img
                    {...uploadWhiteIcon}
                    className="box-content inline-block w-8 h-8 ms-2"
                    alt="Upload"
                  />
                </div>

                <p className="block M16 mt-4 contrast-high">
                  {chooseFileText2}
                </p>
                <div className="mt-5 flex justify-center">
                  {/* TW-rtl doesn't support arbitrary values yet (e.g. "ps-[14px]"): need to manually add it for now */}
                  <span className="bg-overlay-white-10 rounded-[12px] py-[5px] ltr:pr-[14px] rtl:pl-[14px] ltr:pl-[10px] rtl:pr-[10px] flex items-center text-center uppercase font-bold tracking-wide text-[10px] mx-2">
                    <img
                      {...photoIcon}
                      className="box-content inline-block pe-1"
                      alt="Upload"
                    />
                    {photosText}
                  </span>
                  <span className="bg-overlay-white-10 rounded-[12px] py-[5px] pr-[14px] pl-[10px] flex items-center text-center uppercase font-bold tracking-wide text-[10px] mx-2">
                    <img
                      {...videoIcon}
                      className="box-content inline-block pe-1"
                      alt="Upload"
                    />
                    {videosText}
                  </span>
                </div>
                <LegalCopy className="mt-4" />
                {settings.values.bipaDisclaimerEnabled && (
                  <p className="R12 contrast-low mt-3">{bipaText}</p>
                )}
              </UploaderLabel>
            </AnimatedDiv>
          </div>
        </main>
        <Footer
          className={`relative z-10 ${
            settings.values.bipaDisclaimerEnabled ? 'lg:-mt-2' : 'lg:-mt-8'
          } lg:tall:-mt-28 lg:float-right text-center lg:text-right`}
        />
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: hideFooter ? 0 : 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'easeOut', duration: 0.2 }}
          className="hidden lg:short:flex lg:short:fixed bottom-0 w-full h-[40px] items-center justify-center bg-overlay-black-20 backdrop-filter backdrop-blur-[24px]"
          aria-live="polite"
        >
          <p className="R10 uppercase text-gray-300 font-bold tracking-wider">
            {scrollToFooterText}
          </p>
          <img {...dropdownIcon} alt="" className="w-4 h-4 ms-0.5" />
        </motion.div>{' '}
      </UploaderRoot>
    </PageLayout>
  );
}
