import { motion } from 'framer-motion';
import { useAnimatedDiv } from './useAnimatedDiv';

export function AnimatedDiv(props: any) {
  const { variants, animate } = useAnimatedDiv();
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate={animate}
      {...props}
    />
  );
}
