import { useLocalization } from '@/services/localizationService';
import { useTracker } from '@/core/tracking';
import { badgeTypes } from '@/ui/badge';
import { useCallback } from 'react';

export const useTryVideoEnhanceBadge = () => {
  const { t, data } = useLocalization();
  const { track } = useTracker();

  const onClick = useCallback(() => {
    track('video_promotion', 'tapped', {
      source: 'badge',
    });
    document.getElementById('uploader-file-input-home')?.click();
  }, [track]);

  return {
    onClick,
    badgeLabel: data.common.new,
    badgeId: badgeTypes.BadgeVariants.New,
    tryVideoEnhanceText: t(data.common.tryVideoEnhance),
  };
};
