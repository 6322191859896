import { useUserService } from '@/layout/appWrapper/ServiceProvider';
import { OnboardingModal } from '@/layout/menuActions';
import { UserAction } from '@/services/userService';
import { pushModalSelector } from '@/stores/modalStore';
import { useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

export const useShowMobileUsersOnboarding = () => {
  const pushModal = useSetRecoilState(pushModalSelector);

  const mainActionRef = useRef<HTMLDivElement>(null);
  const { isRedirectedFromMobile, clearIsRedirectedFromMobile } =
    useUserService();

  const openOnboardingModal = useCallback(
    (previousAction: UserAction) => {
      pushModal({
        id: 'user:onboarding',
        Modal: OnboardingModal,
        previousAction,
        onClose: () => mainActionRef.current?.focus(),
      });
    },
    [pushModal]
  );

  useEffect(() => {
    const isFromMobile = isRedirectedFromMobile();
    if (isFromMobile) {
      openOnboardingModal(UserAction.Login);
      clearIsRedirectedFromMobile();
    }
  }, [
    clearIsRedirectedFromMobile,
    isRedirectedFromMobile,
    openOnboardingModal,
  ]);
};
