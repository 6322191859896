import { Badge } from '@/ui/badge';
import { useTryVideoEnhanceBadge } from './useTryVideoEnhanceBadge';

export function TryVideoEnhanceBadge() {
  const { onClick, badgeLabel, tryVideoEnhanceText, badgeId } =
    useTryVideoEnhanceBadge();
  return (
    <div
      onClick={onClick}
      className="flex cursor-pointer items-center mb-5 p-2 pe-4 bg-overlay-black-20 rounded-full w-content"
    >
      <Badge
        id={badgeId}
        label={badgeLabel}
        hidden={false}
        absolutePositioned={false}
      />
      <p className="text-white uppercase font-bold text-[10px] tracking-widest ms-2">
        {tryVideoEnhanceText}
      </p>
    </div>
  );
}
