import { ID_PREFIX } from '@/services/uploaderService';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  forId?: string;
  className?: string;
  onClick?: () => void;
};

export function UploaderLabel({
  children,
  forId,
  className = '',
  ...rest
}: Props) {
  return (
    <label
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          const button = document.getElementById(`${ID_PREFIX}-${forId}`);
          button?.click();
        }
      }}
      className={`cursor-pointer ${className}`}
      htmlFor={`${ID_PREFIX}-${forId}`}
      {...rest}
    >
      {children}
    </label>
  );
}
