import useCallbackReference from '@/core/stateManager/useCallbackReference';
import useValueReference from '@/core/stateManager/useValueReference';
import { usePaymentService } from '@/layout/appWrapper/ServiceProvider';
import { useLocalization } from '@/services/localizationService';
import { ID_PREFIX, TaskStatus, TaskType } from '@/services/uploaderService';
import { useUser } from '@/services/userService';
import taskStateFamily from '@/stores/taskStateFamilyStore';
import * as Sentry from '@sentry/nextjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Progress from './Progress';
import useUploadHandler from './useUploadHandler';

export type OnUploadCallback = (taskId: string | null) => void;
export type OnScrollCallback = (hasScrolledDown: boolean) => void;

type Props = {
  id?: string;
  enableDragAndDrop?: boolean;
  onUpload?: OnUploadCallback;
  onScroll?: OnScrollCallback;
  children?: React.ReactNode;
};

export function UploaderRoot({
  id,
  enableDragAndDrop = true,
  onUpload,
  onScroll,
  children,
}: Props) {
  const { t, data } = useLocalization();
  const callOnUpload: OnUploadCallback = useCallbackReference(onUpload);

  const { upload } = useUploadHandler(id!);

  const [task, setTask] = useRecoilState(taskStateFamily(id));
  const resetTask = useResetRecoilState(taskStateFamily(id));
  const [file, setFile] = useState<File>();

  const router = useRouter();
  const fileNumber = useRef<number>(0);
  const { isBusinessUser } = usePaymentService();

  // Drag leave handling
  const [willResetTask, setWillResetTask] = useState(false);
  const getCurrentWillResetTask = useValueReference(willResetTask);

  const { data: user } = useUser();

  const isBusiness = isBusinessUser(user);

  useEffect(() => {
    if (willResetTask) {
      const timeout = window.setTimeout(() => {
        if (getCurrentWillResetTask()) {
          resetTask();
        }
      }, 50);
      return () => window.clearTimeout(timeout);
    }
  }, [willResetTask, getCurrentWillResetTask, resetTask]);

  // Boolean flags
  const isUploading =
    task.status === TaskStatus.Uploading ||
    task.status === TaskStatus.Processing;
  const isOverlayVisible = task.status !== TaskStatus.Pristine;

  // Restore scroll
  const scrollContainerRef: React.MutableRefObject<any> = useRef();
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    if (isOverlayVisible) {
      setScrollTop(scrollContainerRef.current!.scrollTop);
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [isOverlayVisible]);

  useEffect(() => {
    if (!isOverlayVisible) {
      scrollContainerRef.current.scrollTop = scrollTop;
    }
  }, [isOverlayVisible, scrollTop]);

  const onContainerScroll = useCallback(() => {
    if (onScroll) {
      onScroll(scrollContainerRef.current?.scrollTop > 25);
    }
  }, [onScroll]);
  useEffect(() => {
    const ref = scrollContainerRef.current?.addEventListener(
      'scroll',
      onContainerScroll
    );
    return () => ref?.removeEventListener('scroll', onContainerScroll);
  }, [onContainerScroll]);

  const handleUpload = async (
    files: FileList | null,
    from: 'browse' | 'drag'
  ) => {
    if (!files) return;
    setFile(files[0]);
    fileNumber.current = files.length;

    delete router.query.taskId;
    router.push(router);

    if (!isUploading) {
      upload(files, from, t, data)
        .then((taskId) => typeof taskId !== 'undefined' && callOnUpload(taskId))
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
    }
  };

  return (
    <div
      ref={scrollContainerRef}
      className={`h-full relative ${
        isOverlayVisible
          ? 'overflow-hidden'
          : 'overflow-auto lg:tall:overflow-hidden'
      }`}
      onDragOver={(e) => {
        if (enableDragAndDrop) {
          e.preventDefault();
          e.dataTransfer.dropEffect = 'copy';

          if (!isUploading) {
            // Deny drag leave
            setWillResetTask(false);

            if (task.status !== 'draft') {
              setTask({ status: 'draft' });
            }
          }
        }
      }}
      onDrop={(e) => {
        if (enableDragAndDrop) {
          e.preventDefault();
          if (!isUploading) setWillResetTask(true);
          handleUpload(e.dataTransfer.files, 'drag');
        }
      }}
      onDragLeave={() => {
        if (enableDragAndDrop) {
          if (!isUploading) {
            setWillResetTask(true);
          }
        }
      }}
      onDragEnd={() => {
        if (enableDragAndDrop) {
          if (!isUploading) {
            resetTask();
          }
        }
      }}
    >
      <input
        multiple={isBusiness}
        type="file"
        accept={
          user?.monetization?.hasIncompleteSubscription
            ? 'image/*,.heic,.tga'
            : 'image/*,.heic,.tga,video/*'
        }
        id={`${ID_PREFIX}-${id}`}
        className="invisible block w-0 h-0 overflow-hidden"
        onClick={(e) => {
          (e.target as HTMLInputElement).value = ''; // Fix same file picking
        }}
        onChange={(e) => handleUpload(e.target.files, 'browse')}
      />
      <div
        className={`h-full relative z-0 ${
          isOverlayVisible ? 'pointer-events-none' : ''
        }`}
        key="content"
      >
        {children}
      </div>
      <AnimatePresence>
        {/* TODO: grab focus on upload */}
        {isOverlayVisible && (
          <motion.div
            key="progress"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 z-60"
          >
            <Progress
              mediaType={file?.type || TaskType.Video}
              isBulkUpload={fileNumber.current > 1}
              forId={id}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
