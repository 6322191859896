import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { authState } from '@/stores/authStore';
import { useUser } from '@/services/userService';
import LoginToRedeemModal from './LoginToRedeemModal';

export const useRedeemGiftCode = () => {
  const { mutate: mutateUser } = useUser();
  const { isGuestToken } = useRecoilValue(authState);
  const pushModal = useSetRecoilState(pushModalSelector);
  const router = useRouter();
  const [redeemCodeValue, setRedeemCodeValue] = useState<string>();

  useEffect(() => {
    const { redeemCode } = router.query;
    if (redeemCode) {
      setRedeemCodeValue(redeemCode as string);
    }
  }, [router.query]);

  useEffect(() => {
    if (redeemCodeValue) {
      if (isGuestToken) {
        pushModal({
          id: 'user:logintoredeem',
          Modal: LoginToRedeemModal,
          isGuestToken: true,
          onClose: () => mutateUser(),
        });
      } else {
        pushModal({
          id: 'user:logintoredeem',
          Modal: LoginToRedeemModal,
          isGuestToken: false,
          redeemCodeValue,
          onClose: () => mutateUser(),
        });
      }
    }
  }, [pushModal, redeemCodeValue, mutateUser, isGuestToken]);
};
