import { AnimatePresence, motion } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { CloseIcon } from '@/ui/icons';
import React from 'react';
import Button from './Button';
import isOpenState from './isOpenState';

function Navigation({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useRecoilState(isOpenState);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className={`h-full w-full flex justify-end ${className}`}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-overlay-black-70"
            onClick={() => setIsOpen(false)}
          />
          <motion.nav
            className="section-middleground w-full max-w-sm p-6 space-y-6 ms-6"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween' }}
          >
            <button type="button" onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </button>
            {children}
          </motion.nav>
        </div>
      )}
    </AnimatePresence>
  );
}

Navigation.Button = Button;

export default Navigation;
