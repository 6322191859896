import { useRecoilState } from 'recoil';
import hamburgerIcon from '@/public/images/icons/hamburger.svg';
import isOpenState from './isOpenState';

export default function Button({ className = '' }: { className?: string }) {
  const [isOpen, setIsOpen] = useRecoilState(isOpenState);
  return (
    <button
      data-cy="hamburger-btn"
      className={`${className}`}
      aria-pressed={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      type="button"
    >
      <img {...hamburgerIcon} alt="Open menu" style={{ minWidth: 20 }} />
    </button>
  );
}
