import settingsState from '@/stores/settingsStore';
import { useRecoilValue } from 'recoil';

let animatedDivCount = 0;

export const resetAnimatedDivCount = () => {
  animatedDivCount = 0;
};

export function getEntryAnimationVariants() {
  const delay = animatedDivCount++;
  /** @type {import('framer-motion').Variants} */
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: delay * 0.1,
      },
    },
  };
  return variants;
}

export const useAnimatedDiv = () => {
  const settings = useRecoilValue(settingsState);

  return {
    variants: getEntryAnimationVariants(),
    animate: settings.areReady ? 'visible' : 'hidden',
  };
};
