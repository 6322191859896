import { useEffect } from 'react';

export type MediaQueryCallback = (doesMatch: boolean) => void;

export default function useMediaQuery(
  mediaQuery: string,
  cb: MediaQueryCallback
) {
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    cb(mediaQueryList.matches);

    const onMediaQueryListChange = (ev: MediaQueryListEvent) => {
      cb(ev.matches);
    };

    if (typeof mediaQueryList.addEventListener === 'function') {
      mediaQueryList.addEventListener('change', onMediaQueryListChange);
      return () => {
        mediaQueryList.removeEventListener('change', onMediaQueryListChange);
      };
    }
  }, [mediaQuery, cb]);
}
