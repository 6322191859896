import { useTracker } from '@/core/tracking';
import { usePageNameTracking } from '@/core/tracking/usePageNameTracking';
import { useRedeemGiftCode } from '@/features/giftCode/useRedeemGiftCode';
import { useResponsiveComparer } from '@/features/imageComparer/useResponsiveComparer';
import { useShowMobileUsersOnboarding } from '@/features/mobileUsers/useShowMobileUsersOnboarding';
import { useLocalization } from '@/services/localizationService';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import settingsState from '@/stores/settingsStore';
import LinkWithQuery from '@/ui/content/LinkWithQuery';
import { resetAnimatedDivCount } from './animatedDiv/useAnimatedDiv';

const filePickerId = 'home';

export const useHomeLayout = () => {
  const settings = useRecoilValue(settingsState);
  const router = useRouter();
  const { track } = useTracker();
  const { t, data } = useLocalization();
  const comparerConfig = useResponsiveComparer();
  useRedeemGiftCode();
  usePageNameTracking('home');
  useShowMobileUsersOnboarding();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const onComparerSlide = useCallback(() => {
    if (isFirstSlide) {
      track('homepage', 'slider_interaction');
      setIsFirstSlide(false);
    }
  }, [track, isFirstSlide]);

  resetAnimatedDivCount();

  const onUpload = useCallback(
    (taskId: string) =>
      taskId &&
      router.replace(
        {
          pathname: `${router.pathname}result`,
          query: { ...router.query, taskId },
        },
        undefined,
        { shallow: true }
      ),
    [router]
  );

  const [animateHeader, setAnimateHeader] = useState<boolean>(false);
  const [hideFooter, setHideFooter] = useState<boolean>(false);
  const onScroll = useCallback(
    (shouldAnimate: boolean) => {
      setAnimateHeader(shouldAnimate);

      if (shouldAnimate && !hideFooter) {
        setHideFooter(true);
      }
    },
    [hideFooter]
  );

  const onPrivacyPolicyClick = useCallback((e) => e.stopPropagation(), []);

  return {
    filePickerId,
    comparerConfig,
    onComparerSlide,
    onUpload,
    onScroll,
    animateHeader,
    hideFooter,
    settings,
    titleText: t(data.home.title),
    subtitleText: t(data.home.subtitle),
    chooseFileText1: t(data.home.chooseFiles),
    chooseFileText2: t(data.home.chooseFile2),
    photosText: t(data.common.photos),
    videosText: t(data.common.videos),
    scrollToFooterText: t(data.footer.scrollToFooter),
    bipaText: t(data.common.bipaDisclaimer, {
      privacy: (text) => (
        <LinkWithQuery path="/privacy-and-cookie-policy">
          <a
            className="underline"
            target="_blank"
            onClick={onPrivacyPolicyClick}
          >
            {text}
          </a>
        </LinkWithQuery>
      ),
    }),
  };
};
