import { ModalWrapper } from '@/core/modal';
import { useTracker } from '@/core/tracking';
import { useRedeemService } from '@/layout/appWrapper/ServiceProvider';
import { UserAction, useUser } from '@/services/userService';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useLoginCallbacks } from '../account/login';
import OnboardingModal from '../onboarding/OnboardingModal';
import RedeemModal from './RedeemModal';
import { giftCodeTracker } from './giftCodeTracker';

export default function LoginToRedeemModal({
  id,
  isGuestToken,
  redeemCodeValue,
}: {
  id: string;
  isGuestToken: boolean;
  redeemCodeValue?: string;
}) {
  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { postRedeem } = useRedeemService();
  const { data: user, mutate: mutateUser } = useUser();
  const { track } = useTracker();
  const [, logout] = useLoginCallbacks();

  useEffect(() => {
    track('gift_code', 'submitted', {
      redeemCode: redeemCodeValue,
    });
    giftCodeTracker.trackSubmitted(redeemCodeValue);
  }, [redeemCodeValue, track]);

  const onLogout = async (evt, modalId) => {
    try {
      track('settings', 'logout_tapped', {
        actionValue: `${evt.target.textContent} (CTA)`,
      });

      logout();
      closeModal(modalId);
      mutateUser();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ModalWrapper
      isForeground
      className="p-8 px-6 md:p-12 section-foreground rounded-3xl"
    >
      {isGuestToken && (
        <div className="space-y-4 text-center">
          <h3 className="SB20 text-center">Get your Gift Code! 🎁</h3>
          <p>Login to get your gift code associated to your account</p>
          <button
            type="button"
            className="btn btn--large btn--primary mt-4 mx-auto"
            onClick={() => {
              pushModal({
                id: 'user:onboarding',
                Modal: OnboardingModal,
                previousAction: UserAction.Login,
              });
              closeModal(id);
            }}
          >
            Login
          </button>
        </div>
      )}
      {!isGuestToken && (
        <div className="space-y-4 text-center">
          <h3 className="SB20 text-center">Add your Gift Code! 🎁</h3>
          <p>
            Confirm to apply the gift code to{' '}
            <span className="SB16">{user?.email}</span> <br />
            or login to another account.
          </p>
          <div className="flex">
            <button
              type="button"
              className="btn btn--large btn--secondary mt-4 mx-auto"
              onClick={(evt) => onLogout(evt, id)}
            >
              Switch accounts
            </button>
            <button
              type="button"
              className="btn btn--large btn--primary mt-4 mx-auto"
              onClick={() =>
                postRedeem(redeemCodeValue!)
                  .then(() => {
                    closeModal(id);

                    track('gift_code', 'accepted', {
                      redeemCode: redeemCodeValue,
                    });
                    giftCodeTracker.trackAccepted(redeemCodeValue);

                    pushModal({
                      id: 'user:redeem',
                      Modal: RedeemModal,
                      hasError: false,
                      onClose: () => mutateUser(),
                    });
                  })
                  .catch((error) => {
                    track('gift_code', 'errored', {
                      redeemCode: redeemCodeValue,
                      errorMessage: error.message,
                    });
                    giftCodeTracker.trackFailed(redeemCodeValue, error);

                    pushModal({
                      id: 'user:redeemError',
                      Modal: RedeemModal,
                      hasError: true,
                      errorMessage: error.message,
                      onClose: () => mutateUser(),
                    });
                    closeModal(id);
                  })
              }
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
}
